import React from "react"
import Utils from "../../utils/node.utils"
import { StaticQuery, graphql } from "gatsby"
import StaticPage from "../../components/StaticPage/StaticPage"
import {
  createOverlay,
  createContentList,
} from "../../contentfunctions/contact.js"

import Carousel1 from "../../images/contact/contact-min.jpeg"

const ContactPage = ({ location }) => {
  Utils.NavigateToBrowserLanguage("es", "/contact", location.state)
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )
        const contentMap = Utils.ContentListToMap(
          data.contactContent.item.content
        )
        const formContentMap = Utils.ContentListToMap(
          data.formContent.item.content
        )
        const shopContentMap = Utils.ContentListToMap(
          data.shopContent.item.content
        )

        for (let i in formContentMap) {
          if (contentMap[i]) {
            console.error(
              "duplicate entry in content for ",
              i,
              " ignoring value"
            )
          } else {
            contentMap[i] = formContentMap[i]
          }
        }

        for (let i in shopContentMap) {
          if (contentMap[i]) {
            if (!i.startsWith("seo_")) {
              console.error(
                "duplicate entry in content for ",
                i,
                " ignoring value"
              )
            }
          } else {
            contentMap[i] = shopContentMap[i]
          }
        }

        const img = {
          src: Carousel1,
          altText: contentMap.seo_title.value,
          caption: contentMap.seo_description.value,
        }
        return (
          <StaticPage
            title={contentMap.seo_title.value}
            description={contentMap.seo_description.value}
            img={img}
            originPage="contact"
            language="es"
            location={location}
            contentMap={contentMap}
            commonContentMap={commonContentMap}
            breadCrumbs={["menu_home", "menu_contact"]}
            createContentFun={createContentList}
            createOverlayFun={createOverlay}
            keywords={Utils.GetKeywordsFromTags(data.contactContent.item.tags)}
          />
        )
      }}
    />
  )
}

export default ContactPage

const pageQuery = graphql`
  {
    contactContent: contactContentEses {
      item {
        key
        tags {
          content {
            key
            value
          }
        }
        content {
          key
          value
        }
      }
    }

    commonContent: commonContentEses {
      item {
        key
        content {
          key
          value
        }
      }
    }

    formContent: formContentEses {
      item {
        key
        content {
          key
          value
        }
      }
    }

    shopContent: shopContentEses {
      item {
        key
        content {
          key
          value
          content_type
        }
      }
    }
  }
`
